@charset "utf-8";

@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800");
@import url("common.css");
@import url("layout.css");
@import url("style.css");
@import url("reset.css");



/* contents common */
.logo { display: block; margin-bottom: 2.5rem; padding-top: 3.125rem; transition: all 0.3s;}
.logo a { display: block; padding: 0.75rem;  font-size: 1.25rem; color: #23accc; font-weight: 600; }
.logo em { color: #588ed4; }

.box { position: relative; padding: 30px; border: 1px solid #ddd; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; }
.box.type02 { padding: 10px; }
.box02 { position: relative; padding: 30px; }
.box03 { position: relative; padding: 20px 10px; background: #e3ebf6; -webkit-border-radius: 0.25rem; border-radius: 0.25rem;  }
.box04 { padding: 0 5px;background: #fff; border: 1px solid#ddd; -webkit-border-radius: 0.250rem; border-radius: 0.250rem; }
.secbox.type01 { padding: 1.875rem 0; border-top: 1px solid #ddd; }
.box-dash { border: 1px dashed #ddd; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; padding: 10px; }

.fa-question-circle { font-size: 1rem !important; }

.btn-login { position: absolute; bottom: 100px; left: 28px; width: 200px; }


/* [00] login */
.login { margin: 0 auto; width: 300px; height: 100%; padding: 50px 1.25rem 50px; }
.login fieldset { width: 100%;}
.login-util { width: auto; overflow: hidden; font-size: 0.875rem; }
.login-util li { float: left; margin-left: 10px; }
.login .flex-space-between .form-group { margin-bottom: 0; }
.login .tit01 a,
.login .tit01 a:hover { color: #23accc;}

/* [00] 회원가입 */
.join { margin: 0 auto; width: 600px; height: 100%; padding: 50px 1.25rem 50px; }


/* [00] 메인 */
.main-txt { position: relative; }
.main-txt .tip { position: absolute; bottom: 0; right: 0; color: #888; }
.main-bn img { -webkit-border-radius: 0.25rem; border-radius: 0.25rem; }

.list-noti li { position: relative; margin-top: 10px; }
.list-noti li:first-child { margin-top: 0; }
.list-noti a { display: block; }
.list-noti p { display: inline-block;  }
.list-noti .date { display: inline-block; color: #aaa; position: absolute; top: 50%; right: 20px; margin-top: -12px; }
.wrap-list-noti { position: relative; height: 30px; overflow: hidden; }
.wrap-list-noti .list-noti { padding-right: 50px;  }
.wrap-list-noti .list-noti p { line-height: 28px; line-height: 28px; max-width: 70%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; vertical-align: top; }
.wrap-list-noti.on { height: auto; }
.wrap-list-noti .btn-arrow01 { position: absolute; top: -10px; right: 0; }
.wrap-list-noti.on .btn-arrow01 { background-image: url("/images/icon/arrow02_2.png"); }

.dl-main dd { margin-top: 30px; text-align: center; }
.dl-main .number { display: block; font-size: 1.5rem; }
.dl-main .number em { font-size: 0.875rem; }

.order-increase { text-align: center; }
.order-increase i { display: inline-block; padding: 20px; -webkit-border-radius: 50%; border-radius: 50%; background: #fff; color: #588ed4; font-size: 1.875rem; }
.order-increase span { display: block; }

.main-list { position: relative; overflow: visible; }
.main-list:after { display: block; content: ""; clear: both; }
.main-list li { padding: 5px; }
.main-list .txt08 { display: block; line-height: 1.25rem; margin-bottom: 5px; }
.main-list .txt-small { display: inline-block; line-height: 1rem; }
.main-list .wrap-img { text-align: center; }

.main-jumpo img { margin: 0 auto; width: 85%; max-height: 215px; }

#myChart { width: 400px !important; height: 200px !important; }


/* [01] 가게 */
.top-area { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; }
.comment-box { padding: 15px; background: #f5f5f5; -webkit-border-radius: 3px; border-radius: 3px; }
.store-info-link { width: 100%; overflow: hidden; }
.store-info-link .thumb { margin-right: 10px; float: left; width: 70px; height: 70px; border: 1px solid #ddd; -webkit-border-radius: 10px; border-radius: 10px; overflow: hidden; }
.store-info-link .thumb img { width: 100%; height: auto; }
.store-info-link .name { float: left; }
.store-info-link .name dt { font-weight: bold; }
.store-info-link .name dd { margin-top: 1.125rem; color: #959aaa; }
.store-info-link .comment-box { float: left; }
.store-info { margin-top: 1.5rem; padding-top: 1.5rem; border-top: 1px solid #ddd; }
.store-info dl { margin-top: 10px; width: 100%; overflow: hidden; }
.store-info dl:first-child { margin-top: 0; }
.store-info dt { float: left; width: 80px; font-weight: 600; }
.store-info dd { float: left; }
.btn-tit02 { font-size: 1.5rem; }

.chk-list01 > li { border-top: 1px solid #ddd; padding: 1.875rem 0; }
.chk-list01 .tit04 { margin-bottom: 0; }


/* [02] 메뉴 */
.menu-add-step { display: none; }
.menu-add-step.on { display: block; }

.step { font-size: 0.75rem; border-bottom: 1px solid #ddd; }
.step span { display: block; color: #999 }
.step em { display: block; background: #588ed4; height: 2px; }
.step span b { color: #333; }

.dl-chk-opt dt { display: block; font-weight: 600; }
.dl-chk-opt dd { display: block; }
.dl-chk-opt .font-small { color: #959aaa; }

.wrap-toggle.type01 { padding: 15px; background: #f5f5f5; -webkit-border-radius: 3px; border-radius: 3px; }
.wrap-toggle.type01 .box-toggle { height: 55px; }
.wrap-toggle.type01.on .btn-toggle .fas { -ms-transform: rotate(180deg); -webkit-transform: rotate(180deg); transform: rotate(180deg); }

.copy > li { margin-bottom: 10px; }
.copy .form-group { -ms-flex: 1; -webkit-flex: 1; flex: 1; }

.wrap-copy.type01 .copy > li { border-bottom: 1px solid #ddd; padding: 1.25rem 0; }
.wrap-copy.type01 .copy > li:first-child { border-top: 1px solid #000; }

.menu-add { padding: 10px 0; border-bottom: 1px solid #ddd; }
.menu-add > div { padding: 10px 0; }
.menu-add .txt { width: 60%; }
.menu-add .txt02 { text-indent: -10px; margin-left: 10px; }


/* [03] 주문정산 */
.ui-datepicker-trigger { width: 25px; height: auto; margin-right: 10px; }
.wrap-datepicker { border: 1px solid #e0e0e0; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; padding: 0 0.75rem; }
.wrap-datepicker label img { width: 25px; height: auto; }
.wrap-datepicker input[type="text"] { border: 0 none !important; width: 200px; height: 48px !important; }
.wrap-date-search .btn { height: 50px; line-height: 50px; padding: 0 20px; }
.list-select-visible > li { display: none; }
.list-select-visible > li.on { display: block; }


/* 공지 */
.notice_list .li {border-top: 1px solid #e9e9e9;}
.notice_list .li:first-child {border-top: 0 none;}
.notice_list .li .info_tit {position: relative;min-height: 73px;}
.notice_list .li .info_tit a {position:relative;display:block;padding:25px 70px 24px 23px;}
.notice_list .li .info_tit a:hover {color: #588ed4;}
.notice_list .title { display: inline-block; }
.notice_list .num { display: inline-block; margin-right: 10px;color: #888;}
.notice_list .date { display: inline-block; margin-left: 10px; color: #888; }

.notice-view .info { padding: 20px 0;border-bottom: 1px solid #bbc0cd; }
.notice-view .info .subject { margin-bottom: 8px; font-size: 17px;  font-weight: 700; color: #212329; }
.notice-view .info .date { color: #8e929f; }
.notice-view .doc { padding: 40px 30px; border-bottom: 1px solid #bbc0cd; }


/* map */
#popup-2.popup-content { width: 100%; height: 80%; }
.pop-inner_map { position: relative; margin: 0 auto; max-width: 80%; min-width:80%; height: 100%; -webkit-border-radius: 10px; border-radius: 10px; background: #fff; overflow: hidden; }
.pop-inner_map .pop-conents { max-height: 100%; height: 100% }
.pop-inner_map .tb-list { height: 100%; }
.pop-inner_map .wrap-map { height: 100%; }

/* 
    reactjs-popup css
*/

.popup-content {
    margin: auto;
/*    background: rgb(255, 255, 255); 
    width: 50%;*/
    padding: 5px;
  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }


    @media (max-width: 2130px) {

      /* main */
      .main-sec01 .flex-1 { margin-bottom: 1.5rem; -ms-flex: 0 0 1 50%; -webkit-flex: 0 0 50%; flex: 0 0 50%; }
      .main-jumpo img { width: 250px; max-height: none; }
      .main-jumpo + .tooltip { left: 9% !important; }
      .main-list .wrap-img img { width: 70%; }

    }


    @media (max-width: 1200px) {
      .main-sec01 { display: block; }
      .main-sec01 .flex-1 { -ms-flex: none; -webkit-flex: none; flex: none; }
    }


    @media (max-width: 992px) {
      .box { padding: 10px; }

      .order-search { display: block; margin: 0; }
      .order-search > div { display: block; margin: 0.625rem 0 0 !important; }

      .wrap-date-search { display: block; }
      .wrap-date-search > div { display: block; margin-bottom: 0.625rem; }
      .wrap-date-search .btn { width: 100%; }
      .wrap-date-search .wrap-radio { display: flex; margin: 0 0.625rem 0.625rem; }
      .wrap-date-search .wrap-radio label { -ms-flex: 1; -webkit-flex: 1; flex: 1; display: inline-flex; }
      .wrap-datepicker input[type="text"] { width: 40%; }

      .time-select { display: block; }
      .time-select .flex-1 { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; padding: 0; margin-bottom: 5px; }
      .time-select .selbox { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; margin-right: 5px !important; -ms-flex: 1; -webkit-flex: 1; flex: 1; }
      .time-select .txt05 { line-height: 50px; }
      .time-select .txt05 +.selbox { min-width: 145px; }
      .time-select .txt05 +.selbox +.selbox { margin-right: 0 !important; }
      .time-select .selbox select { padding: 0.625rem 0; }

      .calender-select .flex-align-start > div { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; margin-right: 5px !important; -ms-flex: 1; -webkit-flex: 1; flex: 1; }
	  	.calender-select .flex-align-start .wrap-datepicker { width: 100%; }

    }


    @media (max-width: 768px) {
      .main-txt .absolute-r02 { position: static; margin-top: 10px; display: block; }
      .list-noti .date { display: none; }
      .main-graph { display: block; }
      .main-graph .flex-1 { -ms-flex: none; -webkit-flex: none; flex: none; }
      .main-graph .box > img { width: 100%; height: auto; }
      .main-list li { float: none; width: 100%; margin-top: 10px; }
      .dl05 { height: auto; }

      .top-area { display: block; }
      .top-area .selbox { margin-bottom: 10px; }

      .tab-store01 { margin-top: 0.937rem; }
      .tab-store01 .tab-mn > li { width: 25%; }
    }


    @media (max-width: 576px) {
      .secbox.type01 { padding: 0.937rem 0; }

      .wrap-list-noti .list-noti p { max-width: 65%; }
      #myChart { width: 100% !important; height: auto !important; }

      .store-info-link .thumb { float: none; margin-right: 0; margin-bottom: 0.625rem; }
      .store-info-link .comment-box { float: none; margin-left: 0 !important; }

      .login { width: 100%; }

      .main-txt .tip { position: static; margin-top: 10px; }

      /* .box .tit02.pt-15.pb-15 { padding: 15px 0 45px !important; }
      .secbox.type01 .absolute-r01 { top: 60px; } */

    }