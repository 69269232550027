label { margin-bottom: 0; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl { padding: 0; }

.btn:focus, .btn.focus { -webkit-box-shadow: none; box-shadow: none; }

.c-sidebar { background-color: #222; }
.c-sidebar .c-sidebar-brand { background-color: #222; }
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover { background-color: transparent; color: #fff; }
.c-sidebar .c-sidebar-nav-link:hover { color: #588ed4 !important; background-color: transparent; }
.c-sidebar .c-sidebar-nav-link.c-active { background: #588ed4; color: #fff !important; }
.c-sidebar .c-sidebar-nav-dropdown-toggle::after { -ms-transform: rotate(-180deg); -webkit-transform: rotate(-180deg); transform: rotate(-180deg); }
.c-sidebar-minimized .logo { display: none; }
.c-sidebar-minimized .btn-login { display: none; }
.c-sidebar-nav { padding-top: 4.375em; }

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover { background: #588ed4; }

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar-nav-item:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover { background: #1255aa; color: #fff; }

.m-logo { display: none; }
.m-logo .logo { margin: 0; padding: 0; }
.m-logo .logo a { padding: 0.937rem 0.75rem; }

.fa-pencil-alt { margin-right: 5px !important;}

.react-toast-notifications__container { z-index: 3000 !important;}

.popup-overlay { z-index: 2000 !important;}



    @media (max-width: 992px) {
        .wrap-datepicker input[type="text"] { width: 100%; }
        .react-datepicker-wrapper { width: 50%;}

        .c-header .c-header-toggler { position: absolute; top: 0; left: 0; height: 55px; }

        .m-logo { display: block; margin: 0 auto; }
    }