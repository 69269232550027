@charset "utf-8";


#container { position: relative; background-color:#fff;}
#middle { padding: 1.250rem; }


/* aside */
#aside { position: fixed; top: 0; left: 0; width: 300px; height: 100vh; background-color: #222; overflow-y: auto; z-index: 1000; }
#nav { padding-right: 20px; }
#nav a { display: block; color: #fff; }
.mn-toggle { padding: 0.75rem; color: #888 !important; background-image: url("/images/icon/arrow01_1.png"); background-repeat: no-repeat; background-position: right center; -webkit-background-size: 8px auto; background-size: 8px auto; }
.nav-depth a { padding: 0.75rem; }
.nav-depth a:hover { color: #588ed4 !important; }
.nav-mn .nav-depth { padding-left: 25px; display: none; }
.nav-mn .nav-depth .active a { background-color: #588ed4; }
.nav-mn .nav-depth .active a:hover { color: #fff !important; }
.nav-mn.on .nav-depth { display: block;  }
.nav-mn.on .mn-toggle { background-image: url("/images/icon/arrow01_2.png"); -webkit-background-size: 13px auto; background-size: 13px auto; }


/* contents */
#contents { padding: 50px; /*padding-left: 350px;*/ }
.aside-owner-info { position: absolute; bottom: 180px; left: 0; width: 100%; text-align: center; color: #a3a3a3; font-size: 0.75rem; }
.aside-owner-info a { color: #a3a3a3; font-size: 0.75rem; }
.aside-owner-info em { color: #fff; font-weight: bold; }
.aside-owner-info:hover { color: #a3a3a3; }
.aside-register { position: absolute; bottom: 60px; left: 0; width: 100%; text-align: center; font-size: 14px; }
.aside-register a:hover { color: #23accc; }

.c-sidebar-minimized .aside-owner-info { display: none; }
.c-sidebar-minimized .aside-register { display: none; }


    @media (max-width: 1200px) {
        #aside { left: -350px; }
        #contents { padding: 50px; }
        #m-aside { display: block; padding: 20px 10px; }
        #m-aside h1 { text-align: center; font-weight: normal; font-size: 1.125rem; }
    }


    @media (max-width: 992px) {
        #contents { padding: 20px; }
    }